@use '@angular/material' as mat;
@import 'styles/variables';
@import 'styles/responsive';
@import 'styles/mixins';
@import 'styles/theme';
@import 'normalize-scss/sass/normalize/import-now';
@import './styles/include-components';

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  color: var(--87-opacity);
  background: var(--main-bg);
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  @include breakpoint-min(md) {
    overflow: visible !important;
  }
  max-width: map-get($breakpoints, xl);
  margin: 0 auto;
}

a {
  color: var(--link);
  &:active,
  &:visited,
  &:hover {
    color: var(--link);
  }
}

.ellipsis-container {
  white-space: nowrap;
  min-width: 0;
  .ellipsis {
    display: block;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.ellipsis-multirow {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: center;
    }
  }
}


.page-title {
  position: sticky;
  top: $header-height;
  background: var(--primary);
  padding: 0.5rem 0.5rem 0.5rem calc(40px + 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  color: var(--primary-opposite-text);
  &.live {
    background: var(--warn);
  }
  &.connected {
    background: var(--accent);
  }
  &.paused {
    background: var(--paused-border);
  }
  h3 {
    margin: 0;
    padding: 0;
    height: 40px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }
  .mat-mini-fab.back-button {
    border-radius: 0 50% 50% 0;
    position: absolute;
    left: 0;
  }
  .move-speed {
    flex: 0 0 auto;
    font-size: 2rem;
    margin-right: 6rem;
  }
}

.section-title {
  margin: -1rem -1rem 0.5rem;
  background: var(--primary);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
    padding: 0;
  }
}


table.list {
  font-size: 0.9rem;
  width: 100%;
  th,
  td {
    padding: 0.5rem;
    &:first-of-type {
      padding-left: 1rem;
    }
    &:last-of-type {
      padding-right: 1rem;
    }
  }
  thead {
    tr {
      th {
        top: $header-height;
        z-index: 2;
        position: sticky;
        background: var(--primary);
        height: 56px;
        font-weight: 500;
        white-space: nowrap;
        text-align: left;
        color: var(--primary-opposite-text);
        div {
          &:first-of-type {
            align-items: center;
            &.sortable {
              cursor: pointer;
              .mat-icon {
                display: flex;
                justify-content: flex-start;
                width: 1rem;
              }
            }
          }
        }
      }
    }
    @include breakpoint-max(sm) {
      display: none;
    }
  }
  tbody {
    tr.emptylist {
      td {
        height: 66px;
        font-weight: 500;
        background: transparent;
        padding: 1rem;
      }
    }
    tr:not(.emptylist) {
      td {
        background: var(--overlay-background);
        border-bottom: 1rem transparent;
        &.fit-content {
          width: 1%;
        }
        div {
          display: flex;
          align-items: center;
          height: 40px;
        }
        .list-label {
          display: none;
        }
        .nowrap-container {
          white-space: nowrap;
        }
        .ellipsis-container {
          position: relative;
          .ellipsis {
            position: absolute;
            left: 0;
          }
        }
      }
      a {
        display: flex;
        align-items: center;
      }
      &:nth-of-type(2n + 1) {
        td {
          background: none;
          height: 0.25rem;
          padding: 0;
        }
      }
      &.placeholder {
        position: relative;
        overflow: hidden;
        .nowrap-container {
          > span {
            display: block;
            width: 100%;
            height: 1rem;
            background: var(--12-opacity);
          }
        }
        .ellipsis-container {
          > span {
            display: block;
            width: 100%;
            height: 1rem;
            background: var(--12-opacity);
          }
        }
      }
    }
    @include breakpoint-max(sm) {
      tr:not(.emptylist) {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        padding-bottom: 0.5rem;
        td {
          display: block;
          flex: 1 1 auto;
          padding: 0 1rem;
          width: auto !important;
          &:nth-of-type(1) {
            font-size: 1.2rem;
          }
          &:nth-of-type(2) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(3) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(4) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(5) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(6) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(7) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(8) {
            div {
              height: 20px;
            }
          }
          &:nth-of-type(9) {
            position: absolute;
            top: 0;
            right: 0.5rem;
            padding: 0;
          }
          .list-label {
            display: inline-block;
            color: var(--54-opacity);
            margin-right: 0.25rem;
          }
        }
        &:nth-of-type(2n + 1) {
          background: none;
          padding: 0;
        }
      }
    }
  }
}

.loadmore {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  .mat-progress-spinner{
    margin: 5px 0;
    & circle, .mat-spinner circle {
      stroke: var(--accent);
    }
  }
}

.form-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  > * {
    flex: 1 1 100%;
    margin-left: 1rem;
    &.mat-flat-button {
      margin-left: 1rem;
      height: 36px;
      width: 36px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.form-wrapper {
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.form-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  .mat-button-base {
    margin: 0 0.5rem;
  }
}
