body {
  h4.mat-dialog-title {
    margin: 0 0 1rem 0;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .mat-dialog-actions {
    display: flex;
    justify-content: space-around;
    padding-bottom: 1rem;
  }

  .mat-dialog-container {
    background: var(--overlay-background);
  }
}

