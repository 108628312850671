body {
  .mat-snack-bar-container {
    .mat-simple-snackbar {
      display: flex;
      justify-content: center;
    }
    &.error {
      background: var(--warn);
      color: #fff;
    }
    &.success {
      background: var(--accent);
    }
  }
}

