body {
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    border: none;
    background-color: #fff;
  }
  .mat-primary .mat-slider-thumb {
    background-color: #fff;
  }

  .mat-slider-horizontal .mat-slider-track-background {
    background-color: var(--54-opacity);
  }

  .mat-primary .mat-slider-track-fill {
    background-color: #fff;
  }
}

