.mat-button-loading {
  .mat-button-wrapper {
    visibility: hidden;
  }
  .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }
}
