body {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--primary-text);
    -webkit-text-size-adjust: inherit;
    -webkit-box-shadow: 0 0 0 1000px var(--app-bg) inset;
    &::first-line {
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-size: 16px;
    }
  }

  .mat-input-element {
    font-size: 16px;
  }

  textarea {
    &.mat-input-element {
      overflow: hidden;
    }
  }

  .mat-form-field-infix {
    width: auto;
  }

  .mat-form-field {
    &.readonly {
      .mat-form-field-underline {
        background: none !important;
        border-bottom: 1px dashed var(--87-opacity);
        .mat-form-field-ripple {
          display: none ! important;
        }
      }

      &.ng-invalid {
        .mat-form-field-underline {
          border-color: var(--warn);
        }
      }
    }
  }
  .auto-width {
    .mat-form-field {
      width: auto !important;
    }
    .mat-select-value {
      max-width: 100%;
      width: 100%;
    }
    .mat-select-trigger {
      min-width: 100%;
    }
    .mat-select-arrow-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  .mat-chip-list-wrapper input.mat-input-element {
    margin: 4px !important;
  }

  .mat-chip.mat-standard-chip {
    background-color: var(--primary-second) !important;
    border-radius: 5px;
  }
  .mat-chip-list-wrapper .mat-standard-chip {
    margin: 2px 4px !important;
  }
  .mat-standard-chip {
    min-height: 22px;
  }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: var(--warn) !important;
  }
}
