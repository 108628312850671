// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@angular/material/core/theming/palette' as palette;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$my-custom-typography: mat.define-typography-config(
        $title:  mat.define-typography-level(2.5rem, 1, 700),
        $input: mat.define-typography-level(16px, 1.32, 400)
);

@include mat.core($my-custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$light-primary: mat.define-palette(mat.$light-blue-palette, 800, 100, 900);
$light-accent: mat.define-palette(mat.$green-palette, 700, 200);
$light-warn: mat.define-palette(mat.$red-palette, 800, 200);
$light-theme: mat.define-light-theme((
        color: (
                primary: $light-primary,
                accent: $light-accent,
                warn: $light-warn,
        )
));
@include mat.all-component-themes($light-theme);
:root{
  --max-width: 1920px;
  --navigation-width: 230px;
  --header-height: 3rem;
  --primary: #{mat.get-color-from-palette($light-primary)};
  --accent: #{mat.get-color-from-palette($light-accent)};
  --warn: #{mat.get-color-from-palette($light-warn)};
  --primary-second: #{mat.get-color-from-palette($light-primary, 'darker')};
  --toolbar-button: #fff;
  --warn-second: #{mat.get-color-from-palette($light-warn, 'lighter', 0.1)};
  --accent-second: #{mat.get-color-from-palette($light-accent, 'lighter', 0.1)};
  --main-bg: #D2D2D2;
  --app-bg: #eee;
  --link: #{mat.get-color-from-palette($light-primary)};
  --block-title: #{mat.get-color-from-palette($light-primary)};
  --block-content: #ffffff;
  --overlay-background: #ffffff;
  --primary-text: #{palette.$dark-primary-text};
  --primary-opposite-text: #{palette.$light-primary-text};
  --secondary-text: #{palette.$dark-secondary-text};
  --6-opacity: #{palette.$black-6-opacity};
  --12-opacity: #{palette.$black-12-opacity};
  --54-opacity: rgba(0,0,0,0.54);
  --87-opacity: #{palette.$black-87-opacity};
  --paused-bg: #E2E2E2;
  --paused-border: #4D4D4D;
}
