body {
  div.mat-menu-item {
    pointer-events: none;
    color: #000 !important;
  }

  .mat-menu-panel {
    border-radius: 0;
    min-height: 0;
    &.cart-menu {
      width: auto;
    }
  }

  .mat-menu-item {
    &.title {
      background: var(--main-bg) !important;
      color: var(--primary-text) !important;
    }
  }

  .mat-menu-content {
    padding: 0 !important;
    .mat-button-base {
      width: 100%;
      height: 3rem;
    }
  }
}
