.ps--active-y > .ps__rail-y {
  background: transparent !important;
  margin: 0.2rem;
}

.ps__rail-y .ps__thumb-y {
  width: 3px !important;
  background: var(--54-opacity) !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background: var(--54-opacity) !important;
  width: 6px !important;
}
